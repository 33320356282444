import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CognitoErrorService {

    getErrorMessage(error: Error): string {
        if (error.message === 'Network error') {
            return 'No Internet Connection';
        } else if (error.name === 'UserNotFoundException') {
            return 'Incorrect username or password.';
        } else if (error.message === 'Username/client id combination not found.') {
            return 'Invalid inputs, please check and try again.';
        } else if (error.name === 'InvalidParameterException') {
            if (error.message.includes('userName')) {
                return 'Invalid email. Please use a valid email address.';
            }
            return 'Password length should be less than or equal to 256 characters.';
        } else if (
            (
                error.name === 'LimitExceededException' ||
                error.name === 'NotAuthorizedException' ||
                error.name === 'UsernameExistsException' ||
                error.name === 'CodeMismatchException'
            ) &&
            error.message
        ) {
            return error.message;
        } else {
            return $localize`Something went wrong, please try again`;
        }
    }
}
